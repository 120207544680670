import { ENDPOINT_MENU_GET } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_MENU_GET;

export const Menu = {
  get(params: Endpoints.Tyto.Menu.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, { ...params }, callOpts) as Promise<{
      menuItems: TytoData.MenuItem[];
      session: Data.SessionData;
    }>;
  },
};

import { ENDPOINT_DOMAIN_MESSAGE } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DOMAIN_MESSAGE;

export const DomainMessage = {
  put(
    params: Endpoints.Tyto.Domain.Message.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("put", endpoint, params || {}, callOpts) as Promise<{
      message: TytoData.Domain.Message;
      session: Data.SessionData;
    }>;
  },
};

export function downloadFile(file: File) {
  const link = document.createElement("a");
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export function createAnswersFile(fileName: string, data: any) {
  const file = new File([JSON.stringify(data)], `${fileName}.json`, {
    type: "application/json",
  });

  return file;
}

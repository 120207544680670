import { ENDPOINT_DOMAIN_BILLING } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DOMAIN_BILLING;

export const DomainBilling = {
  get(params: Endpoints.Tyto.DomainBilling.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      billingSummary: TytoData.BillingSummary;
      session: Data.SessionData;
    }>;
  },
};

import { ENDPOINT_EXAM_TAKE_ANSWER } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_EXAM_TAKE_ANSWER;

export const Answer = {
  post(
    params: Endpoints.Tyto.Exam.Take.Answer.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.Exam.Take.Answer.Post>;
  },
};

import { ENDPOINT_LOGIN_URL } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_LOGIN_URL;

const timeoutMinutes = 60 * 24 * 90;

export const LoginAuthenticate = {
  post(
    params: Endpoints.Tyto.LoginAuthenticate.GetParameters,
    callOpts?: CallOpts
  ): Promise<{ error?: any; session: Data.SessionData }> {
    return callWrapper(
      "post",
      endpoint,
      { ...(params || {}), timeoutMinutes },
      {
        circumventChangePasswordCheck: true,
        ...(callOpts || {}),
        omitSessionKey: true,
      }
    ) as Promise<{
      error?: any;
      session: Data.SessionData;
    }>;
  },
};

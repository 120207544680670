import { ENDPOINT_TEAM } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TEAM;

export const Team = {
  get(
    params: Endpoints.Tyto.Team.GetParameters & Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params, callOpts) as Promise<{
      team: TytoData.TeamGet;
      session: Data.SessionData;
    }>;
  },

  post(params: Endpoints.Tyto.Team.PostParameters, callOpts?: CallOpts) {
    return callWrapper("post", endpoint, params, callOpts) as Promise<{
      teamID: number;
      session: Data.SessionData;
    }>;
  },
};

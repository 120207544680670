import { ENDPOINT_DISC_PROFILES_EMAIL_VIEW_READY } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILES_EMAIL_VIEW_READY;

export const DISCProfilesEmailViewReady = {
  post(
    params: Endpoints.Tyto.DISCProfiles.EmailViewReady.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      emailResults: any[];
      session: Data.SessionData;
    }>;
  },
};

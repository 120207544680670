/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { LineInfo } from "../";

import "./PlotLine.scss";

interface Props {
  canSelectUser?: boolean;
  delay: number;
  gradient?: {
    offsetPercent: number;
    color: string;
  }[];
  info: LineInfo;
  isHovered: boolean;
  isSelected: boolean;
  lineKey: "di" | "is" | "sc";
  skipDelay: boolean;
  title: string;
}

export default ({
  canSelectUser,
  delay,
  title,
  gradient,
  info,
  lineKey,
  isHovered,
  isSelected,
  skipDelay
}: Props) => {
  const [bgColor, updateBgColor] = React.useState(
    setBGColor(lineKey, false, gradient)
  );
  const [width, updateWidth] = React.useState(skipDelay ? info.length : 0);

  React.useEffect(() => {
    setTimeout(
      () => {
        updateWidth(info.length);
      },
      skipDelay ? 0 : 650 + delay
    );
  }, []);

  return (
    <div
      className={cx(
        "cc-disc-plot-graph-plot-line",
        (isSelected || (canSelectUser && isHovered)) &&
          "cc-disc-plot-graph-plot-line-isselected"
      )}
      title={title}
      style={{
        background: isSelected ? bgColor : "",
        height: "3px",
        left: info.startX,
        top: info.startY,
        width: `${width}px`,
        transform: `rotate(${info.startY > info.endY ? "-" : ""}${
          info.angle
        }deg)`,
        zIndex: isSelected ? 12 : 10
      }}
    />
  );
};

const setBGColor = (
  lineKey: "di" | "is" | "sc",
  includeTakeOver: boolean,
  gradient?: {
    offsetPercent: number;
    color: string;
  }[]
) => {
  if (!Array.isArray(gradient)) {
    return "";
  }

  if (gradient.length === 1) {
    return `linear-gradient(to right, ${gradient[0].color} 0%, ${gradient[0].color} 100%)`;
  }

  switch (lineKey) {
    case "di":
      return `linear-gradient(to right, ${gradient[0].color} 0%, ${gradient[0].color} 100%)`;
    case "is":
      return `linear-gradient(to right, ${gradient[0].color} 0%, ${gradient[0].color} ${gradient[0].offsetPercent}%, ${gradient[1].color} ${gradient[1].offsetPercent}%)`;
    case "sc":
      return `linear-gradient(to right, ${
        (gradient[1] || gradient[0]).color
      } 0%, ${(gradient[1] || gradient[0]).color} 100%)`;
  }
};

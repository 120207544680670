import { ENDPOINT_TEAM_TOOLS_INVITE_TEMPSESSION } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TEAM_TOOLS_INVITE_TEMPSESSION;

export const TeamToolsInviteTempSession = {
  post(
    params: Endpoints.Tyto.TeamToolsInvite.TempSession.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params, {
      circumventChangePasswordCheck: true,
      ...(callOpts || {}),
    }) as Promise<{
      newSession: Data.SessionData;
      accountSession: Data.SessionData;
      emailKey: string;
      session: Data.SessionData;
    }>;
  },
};

import { ENDPOINT_PREREQUISITE_ENROLLMENTS } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PREREQUISITE_ENROLLMENTS;

export const PrerequisiteEnrollments = {
  get(
    params: Endpoints.Tyto.PrerequisiteEnrollments.GetParameters &
      Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.PrerequisiteEnrollments.Get>;
  },
};

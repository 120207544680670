import { ENDPOINT_CATALOG_CURRICULUM_PUBLICATION_SEARCH_LESSON_CONTENT } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_CATALOG_CURRICULUM_PUBLICATION_SEARCH_LESSON_CONTENT;

export const SearchLessonContent = {
  get(
    params: Endpoints.Tyto.CatalogCurriculumPublication.SearchLessonContent.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, {
      circumventChangePasswordCheck: true,
      ...(callOpts || {}),
    }) as Promise<{
      searchCatalogLessonContent: any[];
      searchID: number;
      histID: number;
      session: Data.SessionData;
    }>;
  },
};

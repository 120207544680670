import { ENDPOINT_TEAMBOARD } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TEAMBOARD;

export const Teamboard = {
  get(
    params: Endpoints.Tyto.Teamboard.GetParameters & Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params, callOpts) as Promise<{
      teamNotices: any[];
      session: Data.SessionData;
    }>;
  },
};

import { ENDPOINT_DOMAIN_MESSAGES } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DOMAIN_MESSAGES;

export const DomainMessages = {
  get(
    params: Endpoints.Tyto.Domain.Messages.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      domainMessages: TytoData.Domain.Message[];
      session: Data.SessionData;
    }>;
  },
};

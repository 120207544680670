import { ENDPOINT_ASSET_ENCODING } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_ASSET_ENCODING;

export const AssetEncoding = {
  post(
    params: Endpoints.Tyto.Asset.Encoding.PostParameters &
      Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Asset.Encoding.Post>;
  },
};

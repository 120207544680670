import {
  DEFAULT_APP_VERSION,
  DEFAULT_APP_BRAND,
  DEFAULT_COMPUTER_ID,
  DEFAULT_SESSION_TIMEOUT_LENGTH,
  ENDPOINT_LOGIN4_URL,
} from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_LOGIN4_URL;

export const LoginAuthenticate4 = {
  post(
    params: Endpoints.Tyto.LoginAuthenticate.GetParameters,
    callOpts?: CallOpts
  ): Promise<{ error?: any; authResults: TytoData.AuthResult[] }> {
    return callWrapper(
      "post",
      endpoint,
      {
        timeOutMinutes: DEFAULT_SESSION_TIMEOUT_LENGTH,
        computerID: DEFAULT_COMPUTER_ID,
        appBrand: DEFAULT_APP_BRAND,
        appVersion: DEFAULT_APP_VERSION,
        ...(params || {}),
      },
      {
        circumventChangePasswordCheck: true,
        ...(callOpts || {}),
        omitSessionKey: true,
      }
    ) as Promise<{
      error?: any;
      authResults: TytoData.AuthResult[];
    }>;
  },
};

import { ENDPOINT_ENROLLMENT_VERIFICATION_REQUEST } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_ENROLLMENT_VERIFICATION_REQUEST;

export const EnrollmentVerificationRequest = {
  put(
    params: Endpoints.Tyto.Enrollment.VerificationRequest.PutParameters &
      Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "put",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Enrollment.VerificationRequest.Put>;
  },
};
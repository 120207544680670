import { ENDPOINT_DISC_PROFILE_MINI } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILE_MINI;

export const DISCProfileMini = {
  put(
    params: Endpoints.Tyto.DISCProfileMini.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("put", endpoint, params || {}, callOpts) as Promise<{
      recordsAffected: number;
      session: Data.SessionData;
    }>;
  },
};

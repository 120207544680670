import { ENDPOINT_TRAINING } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TRAINING;

export const Training = {
  get(
    params: Endpoints.Tyto.Training.GetParameters & Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Training.Get>;
  },
};

import { ENDPOINT_SAVEFORLESSON } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_SAVEFORLESSON;

export const SaveForLesson = {
  post(
    params: Endpoints.Tyto.SaveForLesson.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      asset?: any;
      lesson?: any;
      session: Data.SessionData;
    }>;
  },
};

import { ENDPOINT_CURRICULUM_PUB_CATALOG_ITEMS_TRENDING } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_CURRICULUM_PUB_CATALOG_ITEMS_TRENDING;

export const CurriculumPubCatalogItemsTrending = {
  get(
    params: Endpoints.Tyto.CurriculumPubCatalogItemsTrending.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.CurriculumPubCatalogItemsTrending.Get>;
  },
};

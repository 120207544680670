import { ENDPOINT_TEAM_INFO } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TEAM_INFO;

export const TeamsByFunction = {
  get(
    params: Endpoints.Tyto.TeamsByFunction.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.TeamsByFunction.Get>;
  },
};

import { ENDPOINT_TASKS } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TASKS;

export const Tasks = {
  put(
    params: Endpoints.Tyto.Tasks.PutParameters &
      Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "put",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Tasks.Put>;
  },
};

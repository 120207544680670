import { ENDPOINT_BLOCK } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_BLOCK;

export const Block = {
  get(
    params: Endpoints.Tyto.Block.GetParameters & Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Block.Get>;
  },
};

import { ENDPOINT_TIMEZONES } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TIMEZONES;

export const Timezones = {
  get(
    params: Endpoints.Tyto.TimeZone.GetParameters & Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.TimeZones.Get>;
  },
};

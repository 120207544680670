import { ENDPOINT_TASK_STRUCTURE } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TASK_STRUCTURE;

export const TaskStructure = {
  get(
    params: Endpoints.Tyto.Task.Structure.GetParameters &
      Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Task.Structure.Get>;
  },
};

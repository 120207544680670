import LF from "localforage";
import { LOCAL_FORAGE_CONFIG } from "./constants";

// * Set Config
LF.config(LOCAL_FORAGE_CONFIG);

// * Self explanatory methods
export function setItem(key: string, value: any) {
  if (!key) {
    return false;
  }

  return LF.setItem(key, value);
}

export function getItem(key: string) {
  if (!key) {
    return false;
  }

  return LF.getItem(key);
}

export function removeItem(key: string) {
  if (!key) {
    return false;
  }

  return LF.getItem(key);
}

export function clear() {
  return LF.clear();
}

export function keys() {
  return LF.keys();
}

export async function allItems() {
  try {
    let items: {
      key: string;
      value: any;
      iterationNumber: number;
    }[] = [];

    await LF.iterate((value, key, iterationNumber) => {
      items.push({
        key,
        value,
        iterationNumber,
      });
    });

    return items;
  } catch (err) {
    console.log("Error: ", err);
    return [];
  }
}

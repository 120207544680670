import { ENDPOINT_DOMAIN_INVITATION_EMAIL } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DOMAIN_INVITATION_EMAIL;

export const DomainInvitationEmail = {
  post(
    params: Endpoints.Tyto.DomainInvitationEmail.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      session: Data.SessionData;
    }>;
  },
};

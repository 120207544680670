import { ENDPOINT_DOMAIN } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DOMAIN;

export const Domain = {
  get(params: Endpoints.Tyto.Domain.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      domain: TytoData.Domain;
      session: Data.SessionData;
    }>;
  },
  post(params: Endpoints.Tyto.Domain.PostParameters, callOpts?: CallOpts) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      recordsAffected: number;
      domainID: number;
      session: Data.SessionData;
    }>;
  },
  put(params: Endpoints.Tyto.Domain.PutParameters, callOpts?: CallOpts) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      recordsAffected: number;
      session: Data.SessionData;
    }>;
  },
};

import { ENDPOINT_PLAN_ENROLL } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PLAN_ENROLL;

export const PlanEnroll = {
  post(
    params: Endpoints.Tyto.DevPlanEnrollment.PostRequest,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.DevPlan.Enrollment.Post>;
  },
};

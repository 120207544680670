import { ENDPOINT_TEAM_MEMBERSHIP_PERSON } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TEAM_MEMBERSHIP_PERSON;

export const TeamMembershipPerson = {
  post(
    params: Endpoints.Tyto.TeamMembership.Person.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, { ...params }, callOpts);
  },
  put(
    params: Endpoints.Tyto.TeamMembership.Person.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("put", endpoint, { ...params }, callOpts);
  },
};

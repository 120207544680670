import { ENDPOINT_CATALOG_CURRICULUM_PUBLICATION } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_CATALOG_CURRICULUM_PUBLICATION;

export const CatalogCurriculumPublication = {
  get(
    params: Endpoints.Tyto.CatalogCurriculumPublication.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, {
      circumventChangePasswordCheck: true,
      ...(callOpts || {}),
    }) as Promise<Endpoints.Responses.CatalogCurriculumPublication.Get>;
  },
};

import { ENDPOINT_LAUNCH_ENROLLMENT } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_LAUNCH_ENROLLMENT;

export const LaunchEnrollment = {
  get(
    params: Endpoints.Tyto.Launch.Enrollment.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.Launch.Enrollment.Get>;
  },
  put(
    params: Endpoints.Tyto.Launch.Enrollment.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "put",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.Launch.Enrollment.Put>;
  },
};

import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size,
  title,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      {title && <title>{title}</title>}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M320 368c17.64 0 32 14.36 32 32s-14.36 32-32 32-32-14.36-32-32 14.35-32 32-32m0-48c-44.18 0-80 35.82-80 80s35.82 80 80 80 80-35.82 80-80-35.82-80-80-80zm204.69-31.83c4.62-4.87 4.38-12.64-.59-17.15-115.74-105.32-292.41-105.38-408.22 0-4.96 4.51-5.2 12.28-.59 17.15l16.47 17.37c4.46 4.71 11.81 4.95 16.62.6 97.44-88.13 245.68-88.21 343.22 0 4.81 4.35 12.16 4.1 16.62-.6l16.47-17.37zm111.42-133.98C457.86-8.86 181.84-8.59 3.89 154.19c-4.94 4.52-5.22 12.14-.57 16.95l16.6 17.18c4.52 4.68 12.01 4.93 16.81.54 159.59-145.79 406.82-145.91 566.54 0 4.81 4.39 12.29 4.13 16.81-.54l16.6-17.18c4.65-4.81 4.37-12.44-.57-16.95z"
      />
    </svg>
  );
};

import { ENDPOINT_DOMAIN_BILLINGS } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DOMAIN_BILLINGS;

export const DomainBillings = {
  get(
    params: Endpoints.Tyto.DomainBillings.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      domainBilling: TytoData.DomainBilling[];
      session: Data.SessionData;
    }>;
  },
};

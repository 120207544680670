import { ENDPOINT_EMAIL_QUEUE_STATUS } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_EMAIL_QUEUE_STATUS;

export const EmailQueueStatus = {
  post(
    params: Endpoints.Tyto.EmailQueueStatus.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params, callOpts) as Promise<{
      emailQueueStatus?: string;
      session: Data.SessionData;
    }>;
  },
};

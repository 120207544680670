import { ENDPOINT_EXAM_TAKE_QUESTION } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_EXAM_TAKE_QUESTION;

export const Question = {
  get(
    params: Endpoints.Tyto.Exam.Take.Question.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.Exam.Take.Question.Get>;
  },
};

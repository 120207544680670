import { ENDPOINT_EXAM_TAKE_EVALUATE_RESPONSE } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_EXAM_TAKE_EVALUATE_RESPONSE;

export const EvaluateResponse = {
  put(
    params: Endpoints.Tyto.Exam.Take.Evaluate.Response.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "put",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.Exam.Take.Evaluate.Response.Put>;
  },
};

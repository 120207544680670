import { ENDPOINT_DOMAIN_IMAGE } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DOMAIN_IMAGE;

export const DomainImage = {
  put(params: Endpoints.Tyto.Domain.Image.PostParameters, callOpts?: CallOpts) {
    return callWrapper("put", endpoint, params || {}, callOpts) as Promise<{
      recordsAffected: number;
      session: Data.SessionData;
    }>;
  },
};

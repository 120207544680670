import { ENDPOINT_EXAM_TAKE_TRAINING_INVITES } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_EXAM_TAKE_TRAINING_INVITES;

export const TrainingInvites = {
  get(
    params: Endpoints.Tyto.Exam.Take.TrainingInvites.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.Exam.Take.TrainingInvites.Get>;
  }
};

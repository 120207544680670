import { ENDPOINT_LOGIN_RESET_PASSWORD_URL } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_LOGIN_RESET_PASSWORD_URL;

const timeoutMinutes = 60 * 24 * 90;

export const LoginResetPassword = {
  post(
    params: Endpoints.Tyto.Login.ResetPassword.PostParameters,
    callOpts?: CallOpts
  ): Promise<{ error?: any; session: Data.SessionData }> {
    return callWrapper(
      "post",
      endpoint,
      { ...(params || {}), timeoutMinutes },
      { ...(callOpts || {}), omitSessionKey: true }
    ) as Promise<{
      error?: any;
      session: Data.SessionData;
    }>;
  },
};

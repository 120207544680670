import { ENDPOINT_TEAM_TOOLS_INVITE } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TEAM_TOOLS_INVITE;

export const TeamToolsInvite = {
  post(
    params: Endpoints.Tyto.TeamToolsInvite.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params, callOpts) as Promise<{
      recordsAffected: number;
      pwSessionKey: string;
      session: Data.SessionData;
    }>;
  },
};
